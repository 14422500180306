import { AuthSignInParamsModelSchema } from './schemas/AuthSignInParamsModelSchema'

import { composeMixins } from '@/logic/utils/composeMixins'
import { modelValidationMixin } from '@/logic/modelValidationMixin'

const ComposedModel = composeMixins(modelValidationMixin)(Model)

export default class AuthSignInParams extends ComposedModel {
  static class_name = 'AuthSignInParams'
  static entity = 'auth_sign_in_params'
  static schema = AuthSignInParamsModelSchema

  static fields() {
    return {
      email: this.string(null),
      password: this.string(null),
    }
  }

  static casts() {
    return {
      email: StringCast,
      password: StringCast,
    }
  }

}
