<template lang='pug'>

ion-text.ago(v-if='timestamp')
  | {{ upperFirst(ago) }}

</template>

<script setup>

import { upperFirst } from 'lodash'

import { timeAgo } from '@/logic/time'

const props = defineProps({
  timestamp: {
    type: Date,
  },
  tick: {
    type: Object,
  },
})

const {
  timestamp,
  tick,
} = toRefs(props)

const ago = computed(() => {
  if (!timestamp.value) { return }

  tick.value
  return timeAgo(timestamp.value)
})

</script>

<style scoped lang='stylus'>

.ago
  &:empty
    display: none

</style>
