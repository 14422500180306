<template lang='pug'>

ion-select.ion-text-capitalize(
  v-model='i18n.global.locale'
  :label='$t(`ui.components.LocaleSelect.language`)'
  @ionChange='onChange'
)
  ion-select-option.ion-text-capitalize(
    v-for='locale in i18n.global.availableLocales'
    :key='`locale-${locale}`'
    :value='locale'
  )
    | {{ $t(`locale.name.${locale}`) }}

</template>

<script setup>

import { i18n } from '@/logic/i18n'
import { useAuthStore } from '@/store/auth'

const onChange = (event) => {
  useAuthStore().setSetting('locale', event.detail.value)
}

</script>
