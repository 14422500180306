<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-buttons(slot='end')
        ion-button(
          @click='doShare'
          style='font-size: 1.6em'
        )
         i-mdi-share
      ion-title(v-if='person')
        | {{ person.name }}

  ion-content
    ion-refresher(
      slot='fixed'
      @ion-refresh='doRefresh($event)'
    )
      ion-refresher-content

    ion-fab(
      v-if='isTargetable == true'
      slot='fixed'
      vertical='bottom'
      horizontal='end'
    )
      ion-fab-button(
        v-if='isTarget == false'
        color='primary'
        @click='doAddTarget'
      )
        i-mdi-account-plus(style='font-size: 1.6rem')
      ion-fab-button(
        v-if='isTarget == true'
        color='success'
        @click='askToDestroyTarget'
      )
        i-mdi-account-check(style='font-size: 1.6rem')

    ion-item-group(
      v-if='person'
    )
      .ion-padding
        PersonPhoto(
          :id='person?.id'
        )

</template>

<script setup>

import { showConfirmDialog } from 'vant'

import { add } from 'ionicons/icons'

import { onIonViewWillEnter } from '@ionic/vue'

import { useAuthStore } from '@/store/auth'
import { getRepo } from '@/repos'
import { share } from '@/logic/utils/share'
import { confirmToRemove } from '@/logic/utils/confirmations'

const router = useIonRouter()
const authStore = useAuthStore()
const { slug } = useRoute().params

const { isSignedIn  } = storeToRefs(authStore)

const personRepo = getRepo('people')
const person = computed(() => personRepo.where('slug', slug).first())
const me = computed(() => personRepo.getMe())

const isTargetable = computed(() => {
  return person.value?.id != me.value?.id
})

const targetRepo = getRepo('targets')
const isTarget = computed(() => {
  if (!person.value?.id) { return false }

  return targetRepo
    .where('targetable_type', 'Person')
    .where('targetable_id', person.value.id)
    .get()
    .length > 0
})

const refresh = () => {
  return personRepo.api().readOneBySlug({slug})
    .then(
      (response) => {
      },
      (error) => {
        router.push({path: '/error/404', replace: true})
      },
    )
}

const doShare = () => {
  share({
    title: `${person.value.name} @ WHIM`,
    url: document.location.href,
  })
}

const doRefresh = (event) => {
  refresh()
    .then(()=> event.target.complete())
}

const doAddTarget = () => {
  if (isSignedIn.value == false) {
    router.push({path: '/'})
    return
  }
  targetRepo.api().createOne({
    targetable_type: 'Person',
    targetable_id: person.value.id,
  })
  .then(() => {
    personRepo.api().readMeWithData()
  })
}

const doDestroyTarget = () => {
  const target = targetRepo
    .where('targetable_type', 'Person')
    .where('targetable_id', person.value.id)
    .first()
  targetRepo.api().destroyOne({ id: target.id })
    .then(() => {
      personRepo.api().readMeWithData()
    })
}

const askToDestroyTarget = () => {
  confirmToRemove()
    .then(() => {
      doDestroyTarget()
    })
    .catch(() => {})
}

onIonViewWillEnter(() => {
  if (!person.value) {
    refresh()
  }
})

</script>

<style scoped lang='stylus'>

ion-fab
  margin: 1rem

</style>
