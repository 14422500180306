import _ from 'lodash'

import { requestForRoute } from '@/logic/api/requestForRoute'
import { CircleCategory, Person, CircleTarget } from '@/models'
import { CircleModelSchema } from '@/models/schemas/CircleModelSchema'
import { getRepo } from '@/repos'

import { composeMixins } from '@/logic/utils/composeMixins'
import { modelValidationMixin } from '@/logic/modelValidationMixin'

const ComposedModel = composeMixins(modelValidationMixin)(Model)

export default class Circle extends ComposedModel {
  static class_name = 'Circle'
  static entity = 'circles'
  static schema = CircleModelSchema

  static fields() {
    return {
      touchApi: this.boolean(false),
      id: this.uid(),
      person_id: this.number(null),
      category_id: this.number(null),
      name: this.string(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      person: this.belongsTo(Person, 'person_id'),
      category: this.belongsTo(CircleCategory, 'category_id'),
      circle_targets: this.hasMany(CircleTarget, 'circle_id'),
    }
  }

  static casts() {
    return {
      touchApi: BooleanCast,
      person_id: NumberCast,
      category_id: NumberCast,
      name: StringCast,
      created_at: DateCast,
      updated_at: DateCast,
    }
  }

  static creating(model, record) {
    if (!model.willBeValid(record)) { return false }
  }

  static created(model, _record) {
    if (model.touchApi) {
      if (!model.isLocal) { return }
      getRepo('circles').api().createOne(model.$getAttributes())
        .then((response) => {
          getRepo('circles').where('id', model.id).update(
            _.merge({}, response.response.data, { touchApi: false })
          )
        })
    }
  }

  static updating(model, record) {
    if (!model.willBeValid(record)) { return false }
  }

  static updated(model, _record) {
    if (model.touchApi) {
      if (model.isLocal) { return }
      getRepo('circles').api().updateOne(model.$getAttributes())
        .then((response) => {
          getRepo('circles').where('id', model.id).update(
            _.merge({}, response.response.data, { touchApi: false })
          )
        })
    }
  }

  static deleting(_model) {
  }

  static deleted(model) {
    if (model.isLocal) { return }
    getRepo('circles').api().destroyOne({ id: model.id })
  }

  static config = {
    axiosApi: {
      actions: {
        readAll() {
          return requestForRoute(this, 'circles.readAll')
        },
        readOne({ id }) {
          const data = { id }
          return requestForRoute(this, 'circles.readOne', { data })
        },
        createOne(data) {
          return requestForRoute(this, 'circles.createOne', { data, config: { save: false } } )
        },
        updateOne(data) {
          return requestForRoute(this, 'circles.updateOne', { data, config: { save: false } } )
        },
        destroyOne({ id }) {
          const data = { id }
          return requestForRoute(this, 'circles.destroyOne', { data, config: { delete: id }})
        },
      }
    }
  }

}
