import _ from 'lodash'
import { requestForRoute } from '@/logic/api/requestForRoute'
import { Circle, Target } from '@/models'
import { getRepo } from '@/repos'
import { PersonModelSchema } from '@/models/schemas/PersonModelSchema'

import { composeMixins } from '@/logic/utils/composeMixins'
import { modelValidationMixin } from '@/logic/modelValidationMixin'

const ComposedModel = composeMixins(modelValidationMixin)(Model)

export default class Person extends ComposedModel {
  static class_name = 'Person'
  static entity = 'people'
  static schema = PersonModelSchema

  static fields() {
    return {
      touchApi: this.boolean(false),
      id: this.uid(),
      slug: this.string(''),
      name: this.string(''),
      circles: this.hasMany(Circle, 'person_id'),
      targets: this.morphMany(Target, 'targetable_id', 'targetable_type_entity'),
      photo: this.attr({}),
      collections: this.attr({}),
    }
  }

  static casts() {
    return {
      touchApi: BooleanCast,
      slug: StringCast,
      name: StringCast,
    }
  }

  static updating(model, record) {
    if (!model.willBeValid(record)) { return false }
  }

  static updated(model, _record) {
    if (model.touchApi) {
      model.touchApi = false
      getRepo('people').save(model)
      getRepo('people').api().updateMe(model.$getAttributes())
    }
  }

  static config = {
    axiosApi: {
      actions: {
        readMe() {
          return requestForRoute(this, 'me.readMe')
        },
        readMeWithData() {
          return requestForRoute(this, 'me.readMeWithData')
            .then((response) => {
              const targetsIds = response.response.data.collections.ids.targets
              const targetPeopleIds = response.response.data.collections.ids.target_people
              return Promise.allSettled([
                getRepo('targets').api().readManyByIds({ ids: targetsIds }),
                getRepo('people').api().readManyByIds({ ids: targetPeopleIds }),
              ])
            })
        },
        updateMe({ id, name }) {
          const data = { id, name }
          return requestForRoute(this, 'me.updateMe', { data })
        },
        readManyByIds({ ids }) {
          const data = { ids }
          return requestForRoute(this, 'people.readManyByIds', { data })
        },
        readOne({ id }) {
          const data = { id }
          return requestForRoute(this, 'people.readOne', { data })
        },
        readOneBySlug({ slug }) {
          const data = { slug }
          return requestForRoute(this, 'people.readOneBySlug', { data })
        },
        updatePhoto({ photo }) {
          const data = { photo }
          return requestForRoute(this, 'me.updatePhoto', { data, config: { as: 'form' } })
        },
        destroyPhoto() {
          return requestForRoute(this, 'me.destroyPhoto')
        },
        readPersonalThoughtsSummaries() {
          return requestForRoute(this, 'thoughts_summaries.readAll')
            .then((response) => {
              const receivedFeed = _.chain(response.response.data)
                .omit(['id'])
                .values()
                .flatten()
                .value()
              getRepo('personal_thoughts_summaries').fresh(receivedFeed)
            })
        },
      }
    }
  }

}
