import * as validation from '@/logic/modelValidationMethods'

const modelValidationMixin = (Base) => class extends Base {

  get isValid() {
    return validation.isValid.apply(this)
  }

  willBeValid(data) {
    return validation.isValid.apply(this, [data])
  }

  get errors() {
    return validation.errors.apply(this)
  }

  errorsAt(field) {
    return validation.errorsAt.apply(this, [field])
  }

  get isLocal() {
    return validation.isLocal.apply(this)
  }

}

export {
  modelValidationMixin,
}
