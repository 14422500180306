<template lang='pug'>

ion-input(
  v-model='inputModel'
  :label='labelValue'
  :placeholder='labelValue'
  :error-text='errorsMessage'
  ref='input'
  @ionFocus.once='markTouched'
  @ionInput='debouncedValidate'
)

</template>

<script setup>

import _ from 'lodash'

import { t } from '@/logic/i18n'

const props = defineProps({
  model: {
    type: Object,
    default: () => ({}),
  },
  attr: {
    type: String,
    default: '',
  },
})

const { model } = props
const { attr } = toRefs(props)
const inputModel = toRef(model, attr.value)

const input = ref(null)
const errorsMessage = ref('')

const labelValue = computed(() =>
  _.capitalize(t(`attr.${attr.value}`))
)

const markTouched = () => {
  const classList = input.value?.$el?.classList
  if (!classList) return
  classList.add('ion-touched')
}

const markValid = (isValid) => {
  const classList = input.value?.$el?.classList
  if (!classList) return
  if (isValid) {
    classList.remove('ion-invalid')
    classList.add('ion-valid')
  } else {
    classList.remove('ion-valid')
    classList.add('ion-invalid')
  }
}

const validate = () => {
  const errors = model.errorsAt(attr.value)
  errorsMessage.value = errors.join(', ')
  markValid(errors.length === 0)
}

const debouncedValidate = _.debounce(validate, 300)

</script>
