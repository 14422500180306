import _ from 'lodash'
import { t } from '@/logic/i18n'

const confirmToRemove = () => {
  const action = t('ui.actions.remove')
  return showConfirmDialog({
    title: _.capitalize(t('ui.actions.confirm')),
    message: t('ui.prompts.confirmation', {action}),
    confirmButtonText: _.capitalize(action),
    cancelButtonText: _.capitalize(t('ui.actions.cancel')),
    confirmButtonColor: 'var(--ion-color-danger)',
    closeOnClickOverlay: true,
  })
}


export {
  confirmToRemove,
}
