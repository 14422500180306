<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-buttons(slot='end')
        ion-button(
          v-if='isSignedIn'
          fill='clear'
          router-link='/me/edit'
        )
          i-mdi-account-edit(style='font-size: 1.6rem')
        ion-button(
          v-if='isSignedIn'
          fill='clear'
          @click='$router.push(`/@${me?.slug}`)'
        )
          i-mdi-account-box(style='font-size: 1.6rem')
        ion-button(
          v-if='isSignedIn'
          fill='clear'
          router-link='/settings'
        )
          i-mdi-settings-outline(style='font-size: 1.6rem')
      ion-title.ion-text-capitalize
        | {{ $t('ui.pages.me.title') }}

  ion-content
    ion-refresher(
      slot='fixed'
      @ion-refresh='doRefresh($event)'
    )
      ion-refresher-content

    ion-list(
      v-if='isSignedIn'
    )
      ion-item-group
        ion-item(
          v-if='me?.name'
        )
          ion-label
            span.ion-text-capitalize
              | {{ $t('attr.name') }}: 
            | {{ me?.name }}
        ion-item(
          v-if='user?.email'
        )
          ion-label
            span.ion-text-capitalize
              | {{ $t('attr.email') }}: 
            | {{ user?.email }}
        .ion-padding
          PersonPhoto(
            :id='me?.id'
          )

</template>

<script setup>

import { useAuthStore } from '@/store/auth'
import { getRepo } from '@/repos'

const router = useIonRouter()
const authStore = useAuthStore()
const personRepo = getRepo('people')

const { user, isSignedIn  } = storeToRefs(authStore)
const me = computed(() => personRepo.getMe())

const doRefresh = (event) => {
  authStore
    .fetchAllUserData()
    .then(()=> event.target.complete())
}

</script>
